import React from "react";
import styleVars from "../styles/style-vars";

const CallToAction = ({ onButtonClick }) => {
  return (
    <div className="cta-container">
      <div
        className={`${styleVars.page.xl} pt8 pb8 pt20-ns pb20-ns mt6 mt16-ns`}
      >
        <div className="pa12">
          <div className="center" style={{ maxWidth: 800 }}>
            <h1 className="ma0 pa0 f2 f1-ns tc fw-bold">
              Be the first to get the beta
            </h1>
          </div>
          <div className="tc mt8">
            <button
              className="btn-base btn-contained f5"
              style={{ minWidth: 200, height: 60 }}
              onClick={onButtonClick}
            >
              Get Early Access
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
