import React, { Component } from "react";
import "../layouts/index.css";

import mixpanel from "../lib/mixpanel/index";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SectionBox } from "../components/home";
import CallToAction from "../components/cta";
import { handleScroll } from "../lib/utils";
import RequestDemo from "../components/product/RequestDemo";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: false,
      isRequestDemoOpen: false,
      email: "",
    };
  }

  componentDidMount() {
    mixpanel.track("HOME_PAGE_OPENED");
  }

  handleRequestDemo = (event) => {
    // event.preventdefault()
    this.setState(
      (prevState) => ({
        isRequestDemoOpen: !prevState.isRequestDemoOpen,
      }),
      () => handleScroll(this.state.isRequestDemoOpen)
    );
  };

  render() {
    const headingClassName = "ma0 pa0 f3 f2-ns tc tl-l fw-600";
    const descriptionClassName = "f4 f3-ns ma0 mt4 mt10-ns tc tl-l fw-normal";
    const sideClassName = "col-12 col-6-l mt6 mt0-ns";
    const sideImgClassName = "section-image";
    const bodyClassName = "col-12 col-6-l mw-s-l align-self-center";
    const sectionClassName = "pt20 pb10 pt30-ns pb30-ns";
    return (
      <Layout headerClass="bg-color">
        <SEO title="How It Works" />
        <div className="main-content">
          {this.state.isRequestDemoOpen ? (
            <RequestDemo
              email={this.state.email}
              isOpen={this.state.isRequestDemoOpen}
              handleModal={this.handleRequestDemo}
            />
          ) : null}
          <SectionBox
            heading="How it works"
            headingClass="ma0 pa0 f2 f1-ns tc fw-bold"
            bodyClass="col-12 flex flex-column"
            className="section-slanted-bg-container"
          >
            <p className="f4 f3-ns ma0 mt4 mt10-ns tc fw-normal">
              We made it super easy for you to enjoy using Loqal app.
            </p>
          </SectionBox>
          <SectionBox
            heading="Signup in seconds"
            sideImg="how-it-works/chapter1.svg"
            sideImgClassName={sideImgClassName}
            headingClass={headingClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Never worry about cards or digital wallets. Just link your bank
              account to the Loqal app and make hassle-free, secure payments
              directly from your bank account 24x7.
            </p>
          </SectionBox>
          <SectionBox
            heading=" Scan QR Code at the store"
            sideImg="how-it-works/chapter2.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Make sure you are paying the right shop by scanning the LoqalQR
              code next to the check out register.
            </p>
          </SectionBox>
          <SectionBox
            heading="Add an amount"
            sideImg="how-it-works/chapter3.svg"
            sideImgClassName={sideImgClassName}
            headingClass={headingClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              No need to exchange cards or cash at the register. Enter the
              amount you want pay, and finish your transaction in 1 click.
            </p>
          </SectionBox>
          <SectionBox
            heading=" Payment successful"
            sideImg="how-it-works/chapter4.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Get an instant payment confirmation, and feel free to leave some
              feedback.
            </p>
          </SectionBox>
          <CallToAction onButtonClick={this.handleRequestDemo} />
        </div>
        z
      </Layout>
    );
  }
}
export default HowItWorks;
